import React, { FC } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import DangerouslySetInnerHtml from 'gatsby-theme-dettol/src/components/common/DangerouslySetInnerHtml';
import AccordionCustom from 'gatsby-theme-dettol/src/components/common/AccordionCustom';
import Label from 'gatsby-theme-dettol/src/components/common/Label';
import HTag from 'gatsby-theme-dettol/src/components/common/HTag';
import { Media, MediaInfoBox } from 'gatsby-theme-dettol/src/components/common/MediaInfoBox';
import UmbracoImage from 'gatsby-theme-dettol/src/components/common/Image/UmbracoImage';
import { IHowToUse } from 'gatsby-theme-dettol/src/components/HowToUse/models';

import 'gatsby-theme-dettol/src/components/HowToUse/HowToUse.scss';

const HowToUse: FC<IHowToUse> = (props) => (
  <div className="dt-how-to-use">
    <HTag size={3} regularText={props.titleRegular} boldText={props.titleBold} />
    <Row>
      <Col md={{ span: 10, offset: 1 }}>
        <DangerouslySetInnerHtml className="dt-how-to-use__text" html={props.description} />
      </Col>
    </Row>

    {props.image && props.imageAlt ? (
      <div className="dt-how-to-use__video">
        <Label type="info">{props.actionLabelText}</Label>
        <MediaInfoBox isFullWidth>
          <Media video={props?.videoUrl}>
            <UmbracoImage image={props.image} alt={props.imageAlt} />
          </Media>
        </MediaInfoBox>
      </div>
    ) : null}
    {props.listTitleText && <h2 className="dt-how-to-use__title">{props.listTitleText}</h2>}
    {props.recommendations && props.recommendations.length > 0 ? (
      <AccordionCustom data={props.recommendations} />
    ) : null}
  </div>
);

export default HowToUse;
